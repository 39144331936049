<template>
  <!--begin::Supplier Listing-->
  <div class="supplier-template">
    <PageHeaderCount
      moduleType="supplier"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_suppliers"
      countkey="supplier_status_count"
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'supplier-listing'"
      v-if="getPermission('supplier:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-col cols="4" class="pt-0">
                <h1 class="form-title d-flex margin-auto selected-rows-text">
                  {{ totalSelected }} Selected
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('supplier:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in supplierMoreAction">
                        <v-list-item
                          link
                          v-on:click="updateMoreAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" class="pt-0 col-lg-6">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_suppliers
                          }}</template>
                          <template v-else>{{
                            item.supplier_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col
                cols="12"
                class="pt-0 justify-flex-end d-flex margin-auto col-lg-6 all-project-btn-right"
              >
                <template v-if="getPermission('supplier:create')">
                  <v-btn
                    :disabled="dataLoading"
                    depressed
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    :to="getDefaultRoute('supplier.create')"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="importDialog = true"
                    color="cyan white--text"
                    depressed
                  >
                    <v-icon dark left>mdi-database-import</v-icon> Import
                  </v-btn>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                  depressed
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <!-- <v-btn
                  v-if="!isEngineerChannel()"
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button btn-tab-hide"
                  color="cyan white--text"
                  depressed
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'customer',
                        },
                      })
                    )
                  "
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn> -->
                <!--  <v-menu content-class="custom-menu-list mt-8" left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu> -->
                <!-- <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  :close-on-content-click="false"
                  content-class="white-background"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button btn-tab-hide"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      depressed
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                
                        <v-checkbox
                          dense
                          v-model="defaultColDefs"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          true-value="true"
                          false-value="false"
                          class="mt-0 mb-0"
                        ></v-checkbox>
                        <v-icon class="draggable-drag-icon" right color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu> -->
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                  content-class="white-background"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button btn-tab-hide"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      depressed
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    v-on:change="updateVisible()"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          hide-details
                          class="mt-0 mb-0"
                          v-on:change="updateVisible()"
                        ></v-checkbox>
                        <v-icon class="draggable-drag-icon" right color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <PageTips v-if="false" module="supplier"></PageTips>
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Supplier"
          :basicSearchFields="['barcode', 'company_name', 'display_name']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{
                        'checkbox-width-limit': cols.checkbox,
                        'pointer-events-none': lodash.isEmpty(rowData),
                      }"
                    >
                      <template v-if="cols.checkbox == 1 ? true : false">
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="!lodash.isEmpty(rowData)">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    :class="{
                      'custom-border-top': index == 0,
                      'orange_lighten-5': index % 2 == 0,
                      'table-in-active-row': !data.activated,
                    }"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template v-if="cols.checkbox == 1 ? true : false">
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'activated'">
                          <div
                            class="user-listing-user"
                            style="width: 60px !important"
                          >
                            <v-switch
                              v-model="data.activated"
                              class="mt-0"
                              inset
                              v-on:click.prevent.stop="activeInactive(data)"
                              color="green"
                            ></v-switch>
                          </div>
                        </template>

                        <template v-else-if="cols.field == 'image'">
                          <v-avatar size="70" color="white">
                            <v-img
                              class="supplier-image"
                              :lazy-src="$defaultProfileImage"
                              :src="
                                getProfileImage(data.profile_logo)
                                  ? getProfileImage(data.profile_logo)
                                  : $defaultProfileImage
                              "
                            >
                            </v-img>
                          </v-avatar>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <Barcode
                            route="supplier.detail"
                            :id="data.id"
                            :barcode="data.barcode"
                          ></Barcode>
                        </template>
                        <template v-else-if="cols.field == 'display_name'">
                          <div class="supplier-listing-supplier">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Display Name: </b>
                              <template v-if="data && data.display_name">
                                {{ data.display_name }}
                              </template>
                              <em v-else class="text-muted">
                                no Display Name</em
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Company: </b>
                              <template v-if="data && data.company_name">
                                {{ data.company_name }}
                              </template>
                              <em v-else class="text-muted"> no Company</em>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <!-- v-if="
                                lodash.isEmpty(data.default_person) === false
                              " -->
                              <b>Email: </b>
                              <!-- {{ data.default_person.primary_email }} -->
                              <template v-if="data && data.company_email">
                                {{ data.company_email }}
                              </template>
                              <em v-else class="text-muted"> no email</em>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Phone: </b>
                              <template v-if="data && data.company_number">
                                {{ data.company_number }}
                              </template>
                              <em v-else class="text-muted"> no phone </em>
                              <!-- {{ data.default_person.primary_phone }} -->
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'contact_person'">
                          <div class="customer-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Name: </b>
                              <b
                                class="fw-500"
                                style="font-weight: 700 !important"
                              >
                                <template
                                  v-if="
                                    data &&
                                    data.default_person &&
                                    data.default_person.full_name
                                  "
                                  >{{ data.default_person.full_name }}</template
                                >
                              </b>
                            </p>

                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Display Name: </b>
                              {{
                                data.default_person &&
                                data.default_person.display_name
                              }}
                            </p>
                            <!-- <p class="m-0 custom-nowrap-ellipsis">
                              <b>First Name: </b> {{ data.default_person.first_name }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Last Name: </b> {{ data.default_person.last_name }}
                            </p> -->
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="
                                lodash.isEmpty(data.default_person) === false
                              "
                            >
                              <b>Email Address: </b>
                              <template
                                v-if="
                                  data &&
                                  data.default_person &&
                                  data.default_person.primary_email
                                "
                              >
                                {{ data.default_person.primary_email }}
                              </template>
                              <em v-else class="text-muted">
                                no email address</em
                              >
                            </p>
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="
                                lodash.isEmpty(data.default_person) === false
                              "
                            >
                              <b>Mobile Number: </b>
                              <template
                                v-if="
                                  data &&
                                  data.default_person &&
                                  data.default_person.primary_phone
                                "
                              >
                                {{ data.default_person.primary_phone }}
                              </template>
                              <em v-else class="text-muted">
                                no Mobile Number</em
                              >
                            </p>
                            <template v-if="data.contact_person_count > 1">
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="ml-2"
                              >
                                <span class="font-size-16 font-weight-500">
                                  +{{
                                    data.contact_person_count - 1
                                  }}
                                  More</span
                                >
                              </v-chip>
                            </template>
                          </div>
                        </template>

                        <template v-else-if="cols.field == 'billing_address'">
                          <div class="property-listing-supplier">
                            <p
                              class="m-0 custom-nowrap-ellipsis-four-line word-break"
                              v-if="data.billing"
                            >
                              {{ data.billing.property_address }},
                              {{ data.billing.country }}
                            </p>
                            <p v-else>no address</p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td colspan="8" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no supplier at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Supplier</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Supplier')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <!--  <Dialog :commonDialog="importDialog">
          <template v-slot:title>Import Supplier</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <template>
                    
                    <a href="./../Sample/suppliernewsheet.xlsx" download>
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                      >
                        Download Sample
                      </v-btn></a
                    >
                  </template>
                </v-row>
                <v-row>
                  <template>
                    <div class="container">
                      <form enctype="multipart/form-data" method="POST">
                        <div class="large-4 medium-4 small-4 cell">
                          <label>
                            <input
                              type="file"
                              id="file"
                              ref="file"
                              v-on:change="handleFileUpload()"
                            />
                          </label>
                        </div>
                      </form>
                    </div>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="importRows"
            >
              Import
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="importDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog> -->
        <ImportTemplate
          :dialog="importDialog"
          sample-url="import-samples/SuppliersImport.xlsx"
          api-url="supplier/import"
          title="Import Supplier"
          v-on:close="importDialog = false"
        ></ImportTemplate>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Supplier Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, DOWNLOAD, POST } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import { saveAs } from "file-saver";
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "supplier-list",
  data() {
    return {
      pageTips: false,
      exportLoading: false,
      customThead: [],
      pageModule: "supplier-listing",
      routeAPI: "supplier",
      routeName: "supplier",
      routeDetailName: "supplier.detail",
      status: "all",
      file: "",
      importDialog: false,
      statusList: [],
      moreActions: [
        /*{
          title: "Import Supplier(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        {
          title: "Export Supplier(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },*/
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      supplierMoreAction: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          action: "inactive",
        },
      ],
    };
  },
  components: {
    draggable,
    ImportTemplate,
    PageTips,
    PageHeaderCount,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
  },
  methods: {
    getProfileImage(profile_logo) {
      if (profile_logo && profile_logo.file && profile_logo.file.url) {
        return profile_logo.file.url;
      }
      return null;
    },
    activeInactive(data) {
      const _this = this;
      if (data.activated == false) {
        data.activated = 0;
      } else {
        data.activated = 1;
      }
      _this.$store
        .dispatch(PATCH, {
          url: `single-supplier/${data.activated}/${data.id}`,
          data: {
            id: data.id,
            status: data.activated,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getRows();
        });
    },
    getBillingAddress(billing) {
      let billingArray = new Array();
      if (this.lodash.isEmpty(billing) === false) {
        if (billing.unit_no) {
          billingArray.push(billing.unit_no);
        }
        if (billing.street_1) {
          billingArray.push(billing.street_1);
        }
        if (billing.street_2) {
          billingArray.push(billing.street_2);
        }
        if (billing.zip_code) {
          billingArray.push(billing.zip_code);
        }
      }
      return billingArray.join(", ");
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkSupplierExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("supplier");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    updateMoreAction(param) {
      const _this = this;
      switch (param) {
        case "active":
          _this.bulkSupplierUpdate({
            suppliers: _this.selectedRows,
            status: 1,
          });
          break;
        case "inactive":
          _this.bulkSupplierUpdate({
            suppliers: _this.selectedRows,
            status: 0,
          });
          break;
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    importRows() {
      // const _this = this;
      const file = this.$refs.file.files[0];
      if (!file) {
        alert("No file chosen");
        return;
      }
      const data = new FormData();
      data.append("excel", file);
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: "supplier/import",
          data: data,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    bulkSupplierUpdate(requestObject) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "supplier",
          data: requestObject,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
        });
    },
    bulkSupplierExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "supplier/export",
        })
        .then(({ data }) => {
          saveAs(data, "supplier-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Supplier",
      },
    ]);
    ServiceFormEventBus.$on("reload:staffdata", () => {
      this.getRows();
    });
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    // _this.defaultColDefs = [
    //   {
    //     headerName: "",
    //     field: "id",
    //     sort: null,
    //     visible: true,
    //     fixed: true,
    //     sortable: false,
    //     checkbox: true,
    //     order: 1,
    //   },
    //   {
    //     headerName: "Image",
    //     field: "image",
    //     sort: null,
    //     image: true,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 2,
    //   },
    //   {
    //     headerName: "Supplier #",
    //     field: "barcode",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //   },
    //   {
    //     headerName: "Supplier Info",
    //     field: "display_name",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 4,
    //   },
    //   {
    //     headerName: "Company Address",
    //     field: "billing_address",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 5,
    //   },
    //   {
    //     headerName: "Created Time",
    //     child: "created_at",
    //     field: "added_at",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 7,
    //   },
    //   {
    //     headerName: "Last Modified Time",
    //     child: "modified_at",
    //     field: "updated_at",
    //     sort: null,
    //     visible: false,
    //     fixed: false,
    //     sortable: false,
    //     order: 8,
    //   },
    // ];

    // const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
    //   visible: true,
    // });

    //  _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize = 5;
  },
};
</script>
